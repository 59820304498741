import React, { Component } from 'react'
import firebase from './Firestore.js';
import Card from './Card.js';

class App extends Component {
  constructor (props) {
    super(props)

    this.cardsRef = firebase.firestore().collection('cards')
    this.devicesRef = firebase.firestore().collection('devices')
    this.gameRef = firebase.firestore().collection('games').doc('oVtQsTgdvGjDZ3lU3EdK')
    this.state = {words: [], wordlist: "words", created_at: 0, on: false}
  }

  componentDidMount () {
    this.maybeSetWordlist()
    this.registerDevice()
    this.unsubscribeGame = this.subscribeToGame()
    this.unsubscribeCards = this.subscribeToCards()
    this.timerID = setInterval(() => this.tick(), 1000)
  }

  componentWillUnmount () {
    this.unsubscribeGame()
    this.unsubscribeCards()
    clearInterval(this.timerID)
  }

   maybeSetWordlist () {
    const pageURL = window.location.href;
    const wordlist = pageURL.substr(pageURL.lastIndexOf('/') + 1).trimRight('?#');
    if (!wordlist) {
      return
    }
     console.log("setting game wordlist to ", wordlist)
     this.gameRef.set({
       "wordlist": wordlist,
     })
  }

  subscribeToCards () {
    const component = this
    // let count = 0
    return this.cardsRef.orderBy("created_at", "desc").limit(1)
      .onSnapshot({
        // Listen for document metadata changes
        includeMetadataChanges: true
      }, function(querySnapshot) {
        // count++
        //   querySnapshot.forEach(function(doc) {
        //     var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
        //     var cached = doc.metadata.fromCache ? "Cached" : "Not cached";
        //     console.log(count, cached, source, doc.id, " data: ", doc.data());
        //   });
        // querySnapshot.docChanges().forEach(function(change) {
        //   console.log(count, change.type, change.doc.id, " data: ", change.doc.data());
        // });
      querySnapshot.forEach(function(doc) {
        if (doc.data().colour) {
          component.setState({
            colour: doc.data().colour,
            words: doc.data().words,
            created_at: doc.data().created_at,
            expires_at: doc.data().expires_at,
            on: component.state.device_id === doc.data().created_by
          })
        }
      });
    });
  }

  subscribeToGame () {
    const component = this
    return this.gameRef
      .onSnapshot({
          // Listen for document metadata changes
          includeMetadataChanges: true
        }, function(doc) {
        const wordlist = doc.data().wordlist
        if (wordlist) {
          console.log("updating wordlist to ", wordlist)
          component.setState({
            wordlist: wordlist,
          })
        }
    });
  }

  registerDevice () {
    if (this.state.device_id) {
      return
    }
    if (window.localStorage.getItem('device_id')) {
      this.setState({
        device_id: window.localStorage.getItem('device_id')
      })
      return
    }
    this.devicesRef.add({
      "created_at": firebase.firestore.FieldValue.serverTimestamp(),
      "app_version": window.navigator.appVersion,
    }).then(docRef => {
      this.setState({
        device_id: docRef.id
      })
      window.localStorage.setItem('device_id', docRef.id);
    })
  }

  createACard () {
    this.cardsRef.add({
      "created_at": firebase.firestore.FieldValue.serverTimestamp(),
      "wordlist": this.state.wordlist,
      "created_by": this.state.device_id,
    })
  }

  tick () {
    if (this.state.expires_at) {
      this.setState({
        seconds: Math.min(Math.max(0, Math.floor((this.state.expires_at.toDate().getTime() - (new Date()).getTime()) / 1000)), 30)
      })
    }
  }

  words () {
    const defaultWords = ["?", "?", "?", "?", "?"]
    if (!this.state.expires_at) {
      return defaultWords
    }

    if (this.state.on || this.state.expires_at.toDate() < new Date()) {
      return this.state.words
    }

    return defaultWords
  }

  colour () {
    return (this.state.seconds === 0) ? 'red' : this.state.colour
  }

  render () {
    const component = this
    return (
      <Card
        words={this.words()}
        seconds={this.state.seconds}
        colour={this.colour()}
        deal={() => {component.createACard()}}
      />
    )
  }
}

export default App
