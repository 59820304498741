import React, { Component } from 'react'
import './Card.css'

class Card extends Component {
  render () {
    const words = []

    this.props.words.forEach(w => {
      words.push(<div className="row word"><a
        href={'https://wol.jw.org/en/wol/s/r1/lp-e?q=' + encodeURI(w)}>
        <p>{w}</p></a></div>)
    })

    return (
      <div className="App">
        <div className="container">
          <div className="row my-3"></div>
          <div className="row">
            <div className={'col-2 col-m-1 offset-m-2 logo-strip py-3 ' + this.props.colour} onClick={this.props.deal} style={{cursor: 'pointer'}}><img
              src="/logo-left.png" className="img-fluid align-middle" alt="logo left"></img></div>
            <div className={'col-8 col-m-6 card-body p-5 ' + this.props.colour}>
              {words}
              <div className="row">
                <div className="col-1 offset-10"><p>{this.props.seconds}</p></div>
              </div>
            </div>
            <div className={'col-2 col-m-1 logo-strip py-3 ' + this.props.colour} onClick={this.props.deal} style={{cursor: 'pointer'}}><img src="/logo-right.png"
                                                                                                   className="img-fluid align-middle"
                                                                                                   alt="logo right"></img>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Card
