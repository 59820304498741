import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyDM1LpY4GzThdTb8pNyOLQEXQk84_hijHo",
  authDomain: "seconds-fd4fe.firebaseapp.com",
  databaseURL: "https://seconds-fd4fe.firebaseio.com",
  projectId: "seconds",
  storageBucket: "seconds.appspot.com",
  messagingSenderId: "764777377648",
  appId: "1:764777377648:web:89b98ead0422f891ffe9b5"
};

firebase.initializeApp(config);

export default firebase;